import React, {useEffect} from "react";
import {Grid, Typography, Link} from "@mui/material";
import {theme} from "../Theme/Theme";
import {baseTheme} from "../Theme/Theme";
import projectsBackground from "../Assest/projects_background3.jpg";

const localStyles = {
    background:{
        backgroundImage: `url(${projectsBackground})`,
        backgroundPosition:'center',
        backgroundSize:'cover',
        backgroundAttachment:'fixed',
        backgroundRepeat:'no-repeat',
        minHeight:'200vh',
        height:'auto',
        width:'100%',
        //opacity:0.7,
        [theme.breakpoints.down("md")]:{
            height:'auto'
        },
        [theme.breakpoints.down("sm")]:{
            //height: '50em'
        }
    },
    mainBody:{
        backgroundColor:baseTheme.white,
        padding:'8rem 10rem',
        opacity:0.85,
        minHeight:'100vh',
        height:'auto',
        width:'100%',
        [theme.breakpoints.down("md")]:{
            padding:'2rem',
            minHeight:'100vh'
        },
        [theme.breakpoints.down("sm")]:{
            minHeight:'100vh',
            height:'auto'
        }
    },
    buttonContainer:{
        paddingTop:'2rem'
    },
    mainHeader:{
        height:'100vh',
    },
    webHeaderText:{
        fontSize:'4rem',
        [theme.breakpoints.down("md")]:{
            fontSize:'3rem'
        }
    },
    webHeaderTextContainer:{
        paddingBottom:'10rem',
        paddingRight:'0.5rem',
        paddingLeft: '0.5rem',
        [theme.breakpoints.down("md")]:{
            paddingBottom: '2em'
        }
    },
    webHeaderTextSub:{
        fontSize: '2rem',
        [theme.breakpoints.down("md")]:{
            fontSize:'1.25rem'
        }
    },
    textStyle: {
        [theme.breakpoints.down("md")]:{
            //textAlign:'center',
            padding: '1rem'
        }
    },
    linkStyle: {
        marginBottom: '6rem',
        '&:last-child':{
            marginBottom: 0
        },
        [theme.breakpoints.down("md")]:{
            //textAlign:'center',
            padding: '1rem',
            fontSize:'0.9rem'
        }
    },
    projectList: {
        width: '100%',
        display:'inline-block'
    }

};

export default function Projects (props){

    useEffect(() => {
        //window.scrollTo({ top: 0, behavior: 'smooth' });
        let top = document.getElementById('top');
        top.scrollIntoView({behavior: 'smooth'});
    },[]);

    return(
        <>
            <Grid container direction={'column'} sx={localStyles.background} justifyContent={'center'}>

                {/*projects page header*/}
                <Grid id={'top'} container item direction={'row'} sx={localStyles.mainHeader} >

                    <Grid container item justifyContent={'center'} alignItems={'center'}>
                        <Grid container item sx={localStyles.webHeaderTextContainer} justifyContent={'center'}>
                            <Grid item sx={{backgroundImage:'linear-gradient(to right, RGBA(21,0,80,0.2), RGBA(21,0,80,1))' , padding:'1em', opacity:1, border:`1px solid ${baseTheme.brightPurple}`, borderRadius:'10px'}} >
                                <Typography sx={localStyles.webHeaderText} variant={'h1'} textAlign={'center'} gutterBottom>“Do what you can, with what you have, where you are.”</Typography>
                                <Typography sx={localStyles.webHeaderTextSub} variant={'subtitle1'} textAlign={'center'}> – Theodore Roosevelt </Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>

                {/*projects page body*/}
                <Grid container item direction={'column'} sx={localStyles.mainBody} justifyContent={'center'} >
                    <Grid item sx={localStyles.projectList}>
                        <Typography variant={'h2'} gutterBottom sx={localStyles.textStyle}>Projects</Typography>

                        {/* MyCharts*/}
                        <Typography variant={'h4'} gutterBottom sx={localStyles.textStyle}>MyCharts - in active development</Typography>
                        <Typography variant={'body1'} gutterBottom sx={localStyles.textStyle}>
                            Building a modern full-stack web app for the public. With simple use case. You can create and manage your charts and track whatever value you like.<br/>
                            Using Next.js on front-end and PHP Laravel as a back-end with MySQL database.<br/>
                            The back-end and the database is self hosted. The front-end is on AWS.<br/>
                        </Typography>
                        <Typography gutterBottom sx={localStyles.linkStyle}>
                            <Link href={'https://www.mycharts.uk/'} rel='noopener noreferrer' target='_blank' underline="always">https://www.mycharts.uk/</Link>
                        </Typography>

                        {/* Shoptopus*/}
                        <Typography variant={'h4'} gutterBottom sx={localStyles.textStyle}>Shoptopus - in active development</Typography>
                        <Typography variant={'body1'} gutterBottom sx={localStyles.textStyle}>
                            Building an advanced e-commerce system using PHP, Laravel, React, Next.js <br/>
                            Leading the development of the front end in a team of three developers<br/>
                            Get involved in all aspects of the project<br/>
                        </Typography>
                        <Typography gutterBottom sx={localStyles.linkStyle}>
                        </Typography>

                        {/* Tic Toc Toa AI*/}
                        <Typography variant={'h4'} gutterBottom sx={localStyles.textStyle}>Tic-Tac-Toe AI</Typography>
                        <Typography variant={'body1'} gutterBottom sx={localStyles.textStyle}>
                            I build a small web app that use an AI model I build from ground up implementing a Minimax algorithm <br/>
                            This project was a lot of fun as recently I start study machine learning and AI<br/>
                            The site is hosted on AWS. Fast and performant.<br/>
                        </Typography>
                        <Typography gutterBottom sx={localStyles.linkStyle}>
                            <Link href={'https://main.d234w1vfg1akan.amplifyapp.com/'} rel='noopener noreferrer' target='_blank' underline="always">https://main.d234w1vfg1akan.amplifyapp.com/</Link>
                        </Typography>

                        <Typography gutterBottom sx={localStyles.linkStyle}>
                        </Typography>


                        {/* NPM */}
                        <Typography variant={'h4'} gutterBottom sx={localStyles.textStyle}>React-scrape NPM package</Typography>
                        <Typography variant={'body1'} gutterBottom sx={localStyles.textStyle}>
                            I`m the creator of the small NPM package called react-scrape.
                            It`s a React and Typescript reusable component library. It offers quality easy plug-ins
                            for other developers. To make the development process faster.
                            The components are highly customizable and well-documented.
                            The service is absolutely free and so far has very positive feedback from the developer
                            community.
                        </Typography>
                        <Typography gutterBottom sx={localStyles.linkStyle}>
                            <Link href={'https://www.npmjs.com/package/react-scrape/'} rel='noopener noreferrer' target='_blank' underline="always">https://www.npmjs.com/<br />package/react-scrape</Link>
                        </Typography>

                        {/* Niki Vaszi */}
                        <Typography variant={'h4'} gutterBottom sx={localStyles.textStyle}>Niki Vaszi official web site</Typography>
                        <Typography variant={'body1'} gutterBottom sx={localStyles.textStyle}>
                            I built a complex serverless web app. The front-end is Next.js and the back end and database is Google Firebase.<br/><br/>
                            On the front-end it use Next.js as the core engine with TypeScript. Integrate many library like Redux for manage some important
                            state and persist it.<br/>
                            Also use Axios for handle API calls with the back-end and other API, framer-motion for having some nice transitions, Tailwind CSS,
                            react-helmet...list go`s on.<br/>
                            The back-end hosted Google Firebase.
                            Many unique components are costume built and tested. The website has a good traffic and popular.
                        </Typography>
                        <Typography gutterBottom sx={localStyles.linkStyle}>
                            <Link href={'https://www.nikivaszi.com/'} rel='noopener noreferrer' target='_blank' underline="always">https://www.nikivaszi.com</Link>
                        </Typography>

                        {/* TOC */}
                        <Typography variant={'h4'} gutterBottom sx={localStyles.textStyle}>Thrive On Code</Typography>
                        <Typography variant={'body1'} gutterBottom sx={localStyles.textStyle}>
                            I built a portfolio web site for myself. No surprise there as most developer I prefer the home made. I can have full control over it. I hope you like it.<br/>
                            This project was a simple fast static site. It`s build on React.js using material UI. The layout and components are unique and costume made.<br/>
                            The site is hosted on AWS. Fast and performant. I combined multiple AWS services in order to achieve my goal. Like S3, Route 53, CloudFront, CloudWatch, Certificate Manager.<br/>
                        </Typography>
                        <Typography gutterBottom sx={localStyles.linkStyle}>
                            <Link href={'https://www.gaborvaszi.com/'} rel='noopener noreferrer' target='_blank' underline="always">https://www.gaborvaszi.com/</Link>
                        </Typography>

                        {/* Snake Arena */}
                        <Typography variant={'h4'} gutterBottom sx={localStyles.textStyle}>Snake Arena 1v1</Typography>
                        <Typography variant={'body1'} gutterBottom sx={localStyles.textStyle}>
                            I built an online multiplayer snake game.<br />
                            It is a classic retro snake game with a player vs player spin. Complex game logic and automated looking for game system.<br />
                            Quick and easy way to play together and have fun with your friends and colleagues.<br/>
                        </Typography>
                        <Typography gutterBottom sx={localStyles.linkStyle}>
                            <Link href={'https://snake-arena-1v1.herokuapp.com/'} rel='noopener noreferrer' target='_blank' underline="always">https://snake-arena-1v1.herokuapp.com</Link>
                        </Typography>

                    </Grid>

                </Grid>

            </Grid>
        </>
    )

}