import React from 'react'
import "react-image-gallery/styles/css/image-gallery.css";
import './Gallery-style-overide.css'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import ImageGallery from "react-image-gallery";

const style = {
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
    },
    relativeBox: {
        position: 'relative',
        height: '100%',
        width: '100%',
    },
    contentBox: {
        position: 'relative',
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
};


export const GalleryModal = ({images}) => {

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    return (
        <div>
            <Button onClick={handleOpen}>Gallery</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style.modal}>
                    <Box sx={style.relativeBox}>
                        <Box style={style.contentBox}>
                            <ImageGallery items={images}/>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </div>
    )
}