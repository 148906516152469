import React, {useEffect} from "react";
import {Grid, Typography} from "@mui/material";
import {theme} from "../Theme/Theme";
import {baseTheme} from "../Theme/Theme";
import videoBackground from "../Assest/cool-background4a.jpg";


const localStyles = {
    background:{
        backgroundImage: `url(${videoBackground})`,
        backgroundPosition:'center',
        backgroundSize:'cover',
        backgroundAttachment:'fixed',
        backgroundRepeat:'no-repeat',
        minHeight:'200vh',
        height:'auto',
        width:'100%',
        //opacity:0.7,
        [theme.breakpoints.down("md")]:{
            height:'auto'
        },
        [theme.breakpoints.down("sm")]:{
            //height: '50em'
        }
    },
    mainBody:{

        backgroundColor:baseTheme.white,
        padding:'8rem 10rem',
        opacity:0.85,
        minHeight:'100vh',
        height:'auto',
        width:'100%',
        [theme.breakpoints.down("md")]:{
            padding:'2rem',
            minHeight:'100vh'
        },
        [theme.breakpoints.down("sm")]:{
            minHeight:'100vh',
            height:'auto'
        }
    },
    buttonContainer:{
        paddingTop:'2rem'
    },
    mainHeader:{
        height:'100vh',
    },
    webHeaderText:{
        fontSize:'4rem',
        [theme.breakpoints.down("md")]:{
            fontSize:'3rem'
        }
    },
    webHeaderTextContainer:{
        paddingBottom:'10rem',
        paddingRight:'0.5rem',
        paddingLeft: '0.5rem',
        [theme.breakpoints.down("md")]:{
            paddingBottom: '2em'
        }
    },
    webHeaderTextSub:{
        fontSize: '2rem',
        [theme.breakpoints.down("md")]:{
            fontSize:'1.25rem'
        }
    },
    textStyle: {
        [theme.breakpoints.down("md")]:{
            //textAlign:'center',
            padding: '1rem'
        }
    },
    iframeContainer: {
        position: 'relative',
        paddingBottom: '56.25%', // 16:9 aspect ratio
        height: 0,
        overflow: 'hidden',
        maxWidth: '100%',
        background: '#000',
        marginBottom: '2rem',
        [theme.breakpoints.down("md")]: {
            paddingBottom: '75%', // Adjust for smaller screens
        }
    },
    iframe: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
    }
};

export default function Videos() {

    useEffect(() => {
        //window.scrollTo({ top: 0, behavior: 'smooth' });
        let top = document.getElementById('top');
        top.scrollIntoView({behavior: 'smooth'});
    },[]);

    return(
        <>
            <Grid container direction={'column'} sx={localStyles.background} justifyContent={'center'}>

                <Grid id={'top'} container item direction={'row'} sx={localStyles.mainHeader} >

                    <Grid container item justifyContent={'center'} alignItems={'center'}>
                        <Grid container item sx={localStyles.webHeaderTextContainer} justifyContent={'center'}>
                            <Grid item sx={{backgroundImage:'linear-gradient(to right, RGBA(21,0,80,0.2), RGBA(21,0,80,1))' , padding:'1em', opacity:1, border:`1px solid ${baseTheme.brightPurple}`, borderRadius:'10px'}} >
                                <Typography sx={localStyles.webHeaderText} variant={'h1'} textAlign={'center'} gutterBottom>“Passion is never enough; neither is skill. But try.”</Typography>
                                <Typography sx={localStyles.webHeaderTextSub} variant={'subtitle1'} textAlign={'center'}> – Toni Morrison</Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>

                {/*web page body*/}
                <Grid container item direction={'column'} sx={localStyles.mainBody} justifyContent={'center'} >
                    <Grid item>
                        <Typography sx={localStyles.textStyle} variant={'h2'} gutterBottom>A humble start. The aim is to give some back to the community and create myself a small library of visual notes.</Typography>
                        <Typography sx={localStyles.textStyle} variant={'body1'} gutterBottom>
                            As a coder and developer at my core, creating videos is a new challenge for me, and I'm learning as I go. I appreciate your patience and hope you find the content useful.
                            <br />
                            My aim is to build a library of visual notes that will help both myself and others learn and remember the essential concepts and techniques we need to know. Along the way, I'll be sharing some of the tips and tricks I've picked up.
                            <br />
                            These videos aren't about reaching big numbers; they're about helping us learn and retain valuable knowledge.
                        </Typography>
                        <Typography variant={'h2'} gutterBottom sx={localStyles.textStyle}>My Videos</Typography>
                        <Typography variant={'body1'} sx={localStyles.textStyle}>

                            {/* 2. video */}
                            <Typography variant={'h5'} gutterBottom sx={localStyles.textStyle}>2024 - How to run a cron job inside a Docker container</Typography>
                            <Typography variant={'body1'} gutterBottom sx={localStyles.textStyle}>
                                Technical video on how to run a cron job inside a Docker container. This is a step by step guide, focusing on the technical details. Including a GitHub repository with the code.
                            </Typography>
                            {/* YouTube Video Embed */}
                            <div style={localStyles.iframeContainer}>
                                <iframe
                                    style={localStyles.iframe}
                                    src="https://www.youtube.com/embed/eWCQ1MAI2gg"
                                    title="YouTube video player"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                ></iframe>
                            </div>

                            {/* 1. video */}
                            <Typography variant={'h5'} gutterBottom sx={localStyles.textStyle}>2024 - How to connect a subdomain on CloudFlare to AWS Amplify</Typography>
                            <Typography variant={'body1'} gutterBottom sx={localStyles.textStyle}>
                                Technical video on how to connect a subdomain on CloudFlare to AWS Amplify. This is a step by step guide, focusing on the technical details.
                            </Typography>
                            {/* YouTube Video Embed */}
                            <div style={localStyles.iframeContainer}>
                                <iframe
                                    style={localStyles.iframe}
                                    src="https://www.youtube.com/embed/DgYjyyxk0_c"
                                    title="YouTube video player"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                ></iframe>
                            </div>

                        </Typography>
                    </Grid>

                </Grid>
            </Grid>
        </>
    )
}