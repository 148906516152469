import React from 'react'
import {Grid, Typography} from "@mui/material";
import {localStyles} from "../../Pages/About";
import {FaCheck} from "react-icons/fa";

const hereStyle = {
    containerB: {
        display: 'flex',
        flexDirection: 'column',
        gap: '2rem'
    },
    displayC:{
        padding: '1rem',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem'
    }
}
export const Endors = () => {
    return (
        <div>
            <Typography variant={'h4'} sx={localStyles.textStyleTitle} gutterBottom>Endorsements</Typography>
            <Grid item container sx={hereStyle.containerB}>
                <Grid item container sx={hereStyle.displayC}>
                    <Typography variant={'body1'} sx={localStyles.textStyle}>
                        "I highly recommend Gabor Vaszi as your full-stack or front-end web developer
                        for creating your web service. Having worked with him, I can attest to his exceptional
                        technical expertise, dedication, and project management skills.
                        Gabor will undoubtedly deliver a top-notch web service that exceeds your expectations.
                        Don't hesitate to work with him; he's a perfect choice!"
                    </Typography>
                    <Typography variant={'body2'} sx={localStyles.xpText}>- Imre Tacsi, Founder and Owner of Flexytainer (source Linkedin)</Typography>
                </Grid>
            </Grid>
            <Grid item container sx={hereStyle.containerB}>
                <Grid item container sx={hereStyle.displayC}>
                    <Typography variant={'body1'} sx={localStyles.textStyle}>
                        "I mentored Gabor through his journey becoming an outstanding web developer.
                        He has demonstrated tremendous willingness and endurance to get done what it takes to learn
                        and practice all technical and non-technical aspects, concepts and best practices.
                        I'd highly recommend Gabor for your next full-stack project."
                    </Typography>
                    <Typography variant={'body2'} sx={localStyles.xpText}> - Roland Treiber, Senior Software Engineer- OCP (source Linkedln)</Typography>
                </Grid>
            </Grid>
        </div>
    )
}