import React from "react";
import {Grid, Typography, Hidden} from "@mui/material";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import YouTubeIcon from '@mui/icons-material/YouTube';
import {styled} from "@mui/styles";
import {baseTheme} from "../Theme/Theme";


const FooterMainContainer = styled('footer')(({theme}) => ({
    height:'4rem',
    display:'flex',
    alignItems:'center',
    backgroundColor:baseTheme.inkBlue
}));


export default function Footer(){

    const date = new Date().getFullYear();

    return(
        <>
            <FooterMainContainer>
                <Grid container direction={'row'} >
                    <Grid container item direction={'row'} md={6} justifyContent={'center'} spacing={2} alignItems={'center'}>
                        <Grid item component={'a'} href={'https://www.linkedin.com/in/gabor-vaszi-329b54212/'} rel='noopener noreferrer' target='_blank'>
                            <LinkedInIcon color={'secondary'}/>
                        </Grid>
                        <Grid item component={'a'} href={'https://github.com/GaborVxxx'} rel='noopener noreferrer' target='_blank'>
                            <GitHubIcon color={'secondary'}/>
                        </Grid>
                        <Grid item component={'a'} href={'https://www.youtube.com/@gaborvaszi3234'} rel='noopener noreferrer' target='_blank'>
                            <YouTubeIcon color={'secondary'}/>
                        </Grid>
                    </Grid>
                    <Hidden mdDown>
                    <Grid container item direction={'row'} xs={6} justifyContent={'center'} alignItems={'center'}>
                        <Grid item>
                            <Typography variant={"subtitle1"}>© {date} all rights reserved. </Typography>
                        </Grid>
                    </Grid>
                    </Hidden>
                </Grid>
            </FooterMainContainer>
        </>
    )
}
