// template_i008qqw  (name, email, message)
import React, {useEffect, useRef, useState} from 'react'
import emailjs from '@emailjs/browser'
import Box from "@mui/material/Box";
import {Button, TextField} from "@mui/material";
import {theme} from "../../Theme/Theme";
import {AppAlert} from "../Alert/Alert";
import {control} from "../Alert/Alert";


const localStyle = {
    form: {
        display:'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        gap: '1rem',
        marginBottom: '3rem',
        flexDirection: 'column',
        paddingTop: '1rem',
        [theme.breakpoints.down("md")]:{
            padding: '1rem'
        }
    },
    inputF: {
        width: '50%',
        [theme.breakpoints.down("md")]:{
            width: '100%',
        }
    }
}
export const EmailForm = () => {
    const form = useRef()

    const [loading, setLoading] = useState(false)

    const [type, setType] = useState(control[3])
    const [message, setMessage] = useState('No')
    const [show, setShow] = useState(false)

    useEffect(() => {
        let timer
        if(show){
            timer = setTimeout(() => setShow(false), 6000)
        }

        return () => {
            if(timer){
                clearTimeout(timer)
            }
        }
    },[show])

    const sendEmail = (e) => {
        e.preventDefault()
        setLoading(true)
        setShow(false)
        setType(control[3])
        setMessage('No')

        emailjs
            .sendForm(
                'service_pjzrksl',
                'template_i008qqw',
                form.current,
                'buDO5Xgn9MazjsyH_'
            )
            .then(
                (result) => {
                    //console.log(result.text)
                    //add pop up for success
                    setLoading(false)
                    if (result) {
                        console.log(JSON.stringify(result))
                        setType(control[3])
                        setMessage('Email successfully sent to Gabor. Thank you. :)')
                        setShow(true)
                    }
                },
                (error) => {
                    setLoading(false)
                    setType(control[1])
                    setMessage(error.text)
                    setShow(true)
                    console.log(error.text)

                }
            )
        e.target.reset()
    }

    //form
    const c_form = (
                <Box
                    component={'form'}
                    ref={form}
                    onSubmit={sendEmail}
                    action={'#'}
                    sx={localStyle.form}
                >
                    <TextField
                        id="full-name"
                        label="Full Name"
                        variant="outlined"
                        name={'name'}
                        required
                        placeholder={'John Smith'}
                        type={'text'}
                        sx={localStyle.inputF}
                    />

                    <TextField
                        id="email"
                        label="Email address"
                        variant="outlined"
                        name={'email'}
                        required
                        placeholder={'me@example.com '}
                        type={'email'}
                        sx={localStyle.inputF}
                    />

                    <TextField
                        id="subject"
                        label="Your message"
                        name={'message'}
                        required
                        placeholder={'Hi. ... '}
                        multiline
                        maxRows={6}
                        variant="outlined"
                        sx={localStyle.inputF}
                    />

                        <Button
                            type={'submit'}
                            disabled={loading}
                        >
                            SEND MESSAGE
                        </Button>

                </Box>
    )

    return (
        <Box>
            {c_form}
            {
                show && <AppAlert type={type} message={message} />
            }
        </Box>
    )
}
