import React from 'react'
import Alert from '@mui/material/Alert';

export const control = ['error', 'warning', 'info', 'success']

export const AppAlert = ({type, message}) => {

    if(!control.includes(type)) {
        throw new Error('Wrong type of Alert.')
    }

    if(!message){
        throw new Error('Missing Alert message.')
    }

    return (
        <Alert severity={type}>{message}</Alert>
    )
}