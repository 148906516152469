import React, {useContext, useState} from "react";
import {ThemeProvider} from "@mui/material/styles";
import {theme, ThemeContext} from "./Theme/Theme";
import {BrowserRouter,Route,Switch} from "react-router-dom";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import About from "./Pages/About";
import Resume from "./Pages/Resume";
import Contact from "./Pages/Contact";
import Projects from "./Pages/Projects";
import Videos from "./Pages/Videos";


function App() {


    // working on dark and light swich
    const themes = useContext(ThemeContext);
    //console.log(themes)

    const [selectedIndex, setSelectedIndex] = useState(0);
    const [value, setValue] = useState(0);

    const localStyle = {
        offsetDiv:{
            position: 'absolute',
            top: '0.5px',
            left: 0,
            right: 0,
            bottom: '0.5px',
            overflowX: 'hidden',
            overflowY: 'auto'
        }
    }


  return (
      <>
          <div style={localStyle.offsetDiv}>
          <ThemeProvider theme={theme}>
              <BrowserRouter>
                  <Header value={value} setValue={setValue} selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex} />
                  <Switch>
                      <Route exact path={'/'} render={(props) => <About
                          {...props}
                          setSelectedIndex={setSelectedIndex}
                          setValue={setValue}
                      />} />
                      {/*
                      <Route exact path={'/website'} render={(props) => <SimpleWebsites
                          {...props}
                          setValue={setValue}
                      />} />
                      <Route exact path={'/e-com'} render={(props) => <E_Commerce
                          {...props}
                          setValue={setValue}
                      />} />
                      */}
                      <Route exact path={'/resume'} render={(props) => <Resume
                          {...props}
                          setValue={setValue}
                      />} />
                      <Route exact path={'/projects'} render={(props) => <Projects
                          {...props}
                          setValue={setValue}
                      />} />
                      <Route exact path={'/videos'} render={(props) => <Videos
                          {...props}
                          setValue={setValue}
                      />} />
                      <Route exact path={'/contact'} render={(props) => <Contact
                          {...props}
                          setValue={setValue}
                      />} />
                  </Switch>
                  <Footer />
              </BrowserRouter>
          </ThemeProvider>
          </div>
      </>
  );
}

export default App;
