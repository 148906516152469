import React, {useEffect} from "react";
import {Grid, Typography} from "@mui/material";
import {theme} from "../Theme/Theme";
import {baseTheme} from "../Theme/Theme";
import contactBackground from "../Assest/contact_background.jpg";
import {EmailForm} from "../Components/Email-form/Email-form";


const localStyles = {
    background:{
        backgroundImage: `url(${contactBackground})`,
        backgroundPosition:'center',
        backgroundSize:'cover',
        backgroundAttachment:'fixed',
        backgroundRepeat:'no-repeat',
        minHeight:'200vh',
        height:'auto',
        width:'100%',
        //opacity:0.7,
        [theme.breakpoints.down("md")]:{
            height:'auto'
        },
        [theme.breakpoints.down("sm")]:{
            //height: '50em'
        }
    },
    mainBody:{

        backgroundColor:baseTheme.white,
        padding:'8rem 10rem',
        opacity:0.85,
        minHeight:'100vh',
        height:'auto',
        width:'100%',
        [theme.breakpoints.down("md")]:{
            padding:'2rem',
            minHeight:'100vh'
        },
        [theme.breakpoints.down("sm")]:{
            minHeight:'100vh',
            height:'auto'
        }
    },
    buttonContainer:{
        paddingTop:'2rem'
    },
    mainHeader:{
        height:'100vh',
    },
    webHeaderText:{
        fontSize:'4rem',
        [theme.breakpoints.down("md")]:{
            fontSize:'3rem'
        }
    },
    webHeaderTextContainer:{
        paddingBottom:'10rem',
        paddingRight:'0.5rem',
        paddingLeft: '0.5rem',
        [theme.breakpoints.down("md")]:{
            paddingBottom: '2em'
        }
    },
    webHeaderTextSub:{
        fontSize: '2rem',
        [theme.breakpoints.down("md")]:{
            fontSize:'1.25rem'
        }
    },
    textStyle: {
        [theme.breakpoints.down("md")]:{
            //textAlign:'center',
            padding: '1rem'
        }
    }

};



export default function Contact (props) {

    useEffect(() => {
        //window.scrollTo({ top: 0, behavior: 'smooth' });
        let top = document.getElementById('top');
        top.scrollIntoView({behavior: 'smooth'});
    },[]);


    return(
        <>
            <Grid container direction={'column'} sx={localStyles.background} justifyContent={'center'}>

                {/*web page header*/}
                <Grid id={'top'} container item direction={'row'} sx={localStyles.mainHeader} >

                    <Grid container item justifyContent={'center'} alignItems={'center'}>
                        <Grid container item sx={localStyles.webHeaderTextContainer} justifyContent={'center'}>
                            <Grid item sx={{backgroundImage:'linear-gradient(to right, RGBA(21,0,80,0.2), RGBA(21,0,80,1))' , padding:'1em', opacity:1, border:`1px solid ${baseTheme.brightPurple}`, borderRadius:'10px'}} >
                            <Typography sx={localStyles.webHeaderText} variant={'h1'} textAlign={'center'} gutterBottom>Get in touch.</Typography>
                            <Typography sx={localStyles.webHeaderTextSub} variant={'subtitle1'} textAlign={'center'}>Let's see what we can achieve together.</Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>


                {/*web page body*/}
                <Grid container item direction={'column'} sx={localStyles.mainBody} justifyContent={'center'} >
                    <Grid item>
                        <Typography variant={'h2'} gutterBottom sx={localStyles.textStyle}>I'm an open minded person up for a conversation</Typography>
                        <Typography variant={'body1'} gutterBottom sx={localStyles.textStyle}>
                            Feel free to contact me at your convenience. I will get back to you a soon as possible. I'm looking forward to hearing from you.
                        </Typography>
                        {/*Contact form*/}
                        <EmailForm />
                        <Typography variant={'h2'} gutterBottom sx={localStyles.textStyle}>You can contact me at</Typography>
                        <Typography variant={'body1'} gutterBottom sx={localStyles.textStyle}>
                            <strong>developergaborv@gmail.com</strong>
                        </Typography>
                        <Typography variant={'body1'} sx={localStyles.textStyle}>
                            Or in the links in the footer.
                        </Typography>
                    </Grid>

                </Grid>

            </Grid>
        </>
    )
};