import React, {useEffect, useState} from "react";
import {Button, Container, Grid, Typography, useMediaQuery} from "@mui/material";
import Avatar from '@mui/material/Avatar';
import {baseTheme, theme} from "../Theme/Theme";
import homeBackground from '../../src/Assest/cool-background5a.jpg';
import gabor from '../../src/Assest/Gabor.jpg';
import {Link} from "react-router-dom";
import {MdRocket, MdVerified} from 'react-icons/md';
import {FaCheck, FaGithub, FaGlobe, FaLinkedin, FaYoutube} from "react-icons/fa";
// images
import FX from '../Assest/flexytrainer.jpg'
import Niki from '../Assest/nikivaszi.jpg'

import PortfolioCard from "../Components/Portfolio-card";

import {Endors} from "../Components/Endorse/Endors";


export const localStyles = {
    background:{
        backgroundImage: `url(${homeBackground})`,
        backgroundPosition:'center',
        backgroundSize:'cover',
        backgroundAttachment:'fixed',
        backgroundRepeat:'no-repeat',
        minHeight:'200vh',
        height:'auto',
        width:'100%',
        //opacity:0.7,
        [theme.breakpoints.down("lg")]:{
            minHeight:'200vh',
            height:'auto'
        },
        [theme.breakpoints.down("md")]:{
            height: 'auto'
        },
        [theme.breakpoints.down("sm")]:{

        }
    },
    mainHeader:{
        minHeight:'100vh',
        height:'auto',
        width:'100%',
        [theme.breakpoints.down("lg")]:{
            minHeight:'100vh',
            height:'auto'
        },
    },
    headerText:{
        paddingTop: '2em'
    },
    xpDisplayRight:{
        padding: '1rem 0 1rem 1rem',
        width: '50%',
        [theme.breakpoints.down("md")]: {
            padding: '1rem',
            width: '100%',
        }
    },
    xpDisplayLeft:{
        padding: '1rem 1rem 1rem 0',
        width: '50%',
        [theme.breakpoints.down("md")]: {
            padding: '1rem',
            width: '100%',
        }
    },
    xpDisplayContainer: {
        backgroundColor: baseTheme.lightGrey,
        borderRadius: '9px',
        padding: '1rem',
        width:'100%',
        display:'flex',
        justifyContent: 'flex-start',
        alignItems:'center',
        textAlign:'center',
        [theme.breakpoints.down("md")]:{
            padding: '0.5rem'
        },
    },
    xpIcon:{
        color:baseTheme.brightPurple,
        fontSize: '24px',
    },
    xpSmallIcon:{
        color:baseTheme.brightPurple,
        fontSize: '18px',
    },
    downloadContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    downloadLink:{
        padding: '2rem',
        backgroundColor: baseTheme.brightPurple,
        color: baseTheme.white,
        borderRadius: '9px',
        fontWeight: 700,
        textDecoration: 'none',
        transition: "all ease .5s",
    },
    downloadLinkHover:{
        padding: '2rem',
        backgroundColor: baseTheme.brightLightPurple,
        color: baseTheme.white,
        borderRadius: '9px',
        fontWeight: 700,
        textDecoration: 'none',
        transition: "all ease .5s",
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
    },
    mainBody:{
        backgroundColor:baseTheme.white,
        padding:'8rem 10rem',
        opacity:0.85,
        height:'auto',
        width:'100%',
        [theme.breakpoints.down("lg")]:{
           minHeight:'100vh',
            height:'auto'
        },
        [theme.breakpoints.down("md")]:{
            padding:'2rem',
            minHeight: '100vh'
        },
        [theme.breakpoints.down("sm")]:{
            minHeight:'100vh',
            height:'auto'
        }
    },
    avatarImage:{
        width: '25rem',
        height: '25rem',
        border:`3px solid ${baseTheme.deepPurple}`,
        [theme.breakpoints.down("lg")]:{
            width: '20rem',
            height: '20rem',
        },
        [theme.breakpoints.down("md")]:{
            width: '15rem',
            height: '15rem',
        }
    },
    avatarContainer:{
        [theme.breakpoints.down("md")]:{
            justifyContent:'center',
            alignItems:'flex-start',
            paddingTop: '2em',
            paddingBottom:'2em'
        }
    },
    buttonContainer:{
        paddingTop:'2rem',
        marginBottom:'6rem',
        [theme.breakpoints.down("lg")]:{
            paddingTop: '1rem'
        },
        [theme.breakpoints.down("md")]:{
            paddingTop: '1rem',
            display:'flex',
            justifyContent:'center',
            alignItems:'center'
        }
    },
    textStyle: {
        [theme.breakpoints.down("md")]:{
            //textAlign:'center',
            padding: '1rem'
        }
    },
    textSkillStyle: {
        [theme.breakpoints.down("md")]:{
            //textAlign:'center',
            padding: '1rem',
            fontSize: '1.2rem',
            fontWeight: 600,
        }
    },
    textStyleTitle: {
        [theme.breakpoints.down("md")]:{
            textAlign:'center',
            padding: '1rem'
        }
    },
    linkContainer:{
        display:'flex',
        justifyContent:'center',
        alignItems: 'center',
        gap: '2rem',
        marginTop: '1rem'
    },
    skillsBox:{
        display:'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        gap: '1rem',
        flexDirection: 'column',
        [theme.breakpoints.down("md")]:{
            alignItems: 'center',
            justifyContent: 'flex-start',
            textAlign: 'start'
        }
    },
    skillSubBox:{
        [theme.breakpoints.down("md")]: {
            flexDirection: 'column'
        }
    },
    xpText:{
        [theme.breakpoints.down("lg")]:{
            fontSize: '1rem',
        }
    },
    // current work
    cardText:{
        textAlign:'start',
        [theme.breakpoints.down("lg")]:{
            fontSize: '1rem',
        }
    },
    imagesP: {
        maxWidth: '100%',
        borderRadius: '9px'
    },
    imagesContainer:{
        display:'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '30%',
    },
    portfolioBox:{
        display:'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        gap: '1rem',
        flexDirection: 'column',
        flexGrow: 1,
        [theme.breakpoints.down("md")]:{
            alignItems: 'center',
            justifyContent: 'flex-start',
            textAlign: 'start'
        }
    },
};


export default function About (props){


    useEffect(() => {
        //window.scrollTo({ top: 0, behavior: 'smooth' });
        let top = document.getElementById('top');
        top.scrollIntoView({behavior: 'smooth'});
    },[]);

    const midSize = useMediaQuery(theme.breakpoints.down("md"));
    //const smallSize = useMediaQuery(theme.breakpoints.down("sm"));

    const [hover, setHover] = useState(false)

    const handleDownload = async () => {
        const bucketName = 'thriveoncode';
        const objectKey = 'cv.pdf';
        const downloadUrl = `https://${bucketName}.s3.amazonaws.com/${objectKey}`;
        try {
            const response = await fetch(downloadUrl);
            const blob = await response.blob();

            // Create a temporary anchor element
            const downloadLink = document.createElement('a');
            downloadLink.href = URL.createObjectURL(blob);
            downloadLink.download = 'cv.pdf'; // Set the desired file name
            downloadLink.click();

            // Clean up the URL.createObjectURL resources
            URL.revokeObjectURL(downloadLink.href);
        } catch (error) {
            console.error('Error downloading the file:', error);
        }
    };

    const imagesNiki = [
        {
            original: "https://thriveoncode-images-for-slider.s3.eu-west-2.amazonaws.com/niki1.jpg",
            thumbnail: "https://thriveoncode-images-for-slider.s3.eu-west-2.amazonaws.com/niki1.jpg",
            loading: 'lazy'
        },
        {
            original: "https://thriveoncode-images-for-slider.s3.eu-west-2.amazonaws.com/niki2.jpg",
            thumbnail: "https://thriveoncode-images-for-slider.s3.eu-west-2.amazonaws.com/niki2.jpg",
            loading: 'lazy'
        },
        {
            original: "https://thriveoncode-images-for-slider.s3.eu-west-2.amazonaws.com/niki3.jpg",
            thumbnail: "https://thriveoncode-images-for-slider.s3.eu-west-2.amazonaws.com/niki3.jpg",
            loading: 'lazy'
        },
        {
            original: "https://thriveoncode-images-for-slider.s3.eu-west-2.amazonaws.com/niki4.jpg",
            thumbnail: "https://thriveoncode-images-for-slider.s3.eu-west-2.amazonaws.com/niki4.jpg",
            loading: 'lazy'
        },
    ];


    const imagesImi = [
        {
            original: "https://thriveoncode-images-for-slider.s3.eu-west-2.amazonaws.com/imi2.jpg",
            thumbnail: "https://thriveoncode-images-for-slider.s3.eu-west-2.amazonaws.com/imi2.jpg",
            loading: 'lazy'
        },
        {
            original: "https://thriveoncode-images-for-slider.s3.eu-west-2.amazonaws.com/imi-1.jpg",
            thumbnail: "https://thriveoncode-images-for-slider.s3.eu-west-2.amazonaws.com/imi-1.jpg",
            loading: 'lazy'
        },
        {
            original: "https://thriveoncode-images-for-slider.s3.eu-west-2.amazonaws.com/imi-3.jpg",
            thumbnail: "https://thriveoncode-images-for-slider.s3.eu-west-2.amazonaws.com/imi-3.jpg",
            loading: 'lazy'
        },
        {
            original: "https://thriveoncode-images-for-slider.s3.eu-west-2.amazonaws.com/imi4.jpg",
            thumbnail: "https://thriveoncode-images-for-slider.s3.eu-west-2.amazonaws.com/imi4.jpg",
            loading: 'lazy'
        },
    ]

    return(
        <>
            <Grid container direction={'column'} sx={localStyles.background} justifyContent={'center'}>

                {/*main page header*/}
                <Grid id={'top'} container item
                      direction={midSize ? 'column' :'row'}
                      spacing={midSize ? undefined : undefined}
                      justifyContent={midSize ? 'center' : undefined}
                      sx={localStyles.mainHeader} >

                <Grid container item
                      sx={midSize ? undefined: {paddingRight:'9rem'}}
                      direction={'row'} md={6} justifyContent={midSize ? 'center' : 'flex-end'}
                      alignItems={midSize ? 'flex-end' :'center'}>
                    <Grid item  sx={midSize ? localStyles.headerText : undefined}>
                        <Typography variant={'h1'} textAlign={'center'} gutterBottom>Gabor Vaszi</Typography>
                        <Typography variant={'subtitle1'} textAlign={'center'}>Programmer, Manager, Thinker</Typography>
                        <Grid item container direction={'row'} sx={localStyles.linkContainer}>
                            <Grid item>
                                <a rel={'noreferrer'} target={'_blank'} href={'https://www.linkedin.com/in/gabor-vaszi-329b54212/'}><FaLinkedin style={localStyles.xpIcon}/></a>
                            </Grid>
                            <Grid item>
                                <a rel={'noreferrer'} target={'_blank'} href={'https://github.com/GaborVxxx'}><FaGithub style={localStyles.xpIcon}/></a>
                            </Grid>
                            <Grid item>
                                <a rel={'noreferrer'} target={'_blank'} href={'https://www.youtube.com/@gaborvaszi3234'}><FaYoutube style={localStyles.xpIcon}/></a>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item direction={'row'} md={6} sx={localStyles.avatarContainer} alignItems={'center'}>
                    <Grid item >
                        <Avatar src={gabor} sx={localStyles.avatarImage}/>
                    </Grid>
                </Grid>

                </Grid>

                {/*main page body*/}
                <Grid container item direction={'column'} sx={localStyles.mainBody} justifyContent={'center'} >
                    <Grid item container direction={'column'} gap={5}>
                        <Grid item sx={localStyles.downloadContainer}>
                            <button
                                onClick={handleDownload}
                                style={hover ? localStyles.downloadLinkHover : localStyles.downloadLink}
                                onMouseEnter={() => setHover(true)}
                                onMouseLeave={() => setHover(false)}
                            >
                                Download CV
                            </button>
                        </Grid>
                        <Grid item container direction={'row'}>
                            <Grid item sx={localStyles.xpDisplayLeft} xs={6} container>
                                <Grid item sx={localStyles.xpDisplayContainer} direction={'column'} container gap={1}>
                                    <MdVerified style={localStyles.xpIcon}/>
                                    <Typography variant={'body2'}>Experience</Typography>
                                    <Typography variant={'caption'}>5+ years</Typography>
                                </Grid>
                            </Grid>
                            <Grid item sx={localStyles.xpDisplayRight} xs={6}  container>
                                <Grid item sx={localStyles.xpDisplayContainer} direction={'column'} container gap={1}>
                                    <MdRocket style={localStyles.xpIcon}/>
                                    <Typography variant={'body2'}>Projects</Typography>
                                    <Typography variant={'caption'}>15+ Completed Projects</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Typography variant={'h2'} sx={localStyles.textStyleTitle} gutterBottom>About</Typography>
                        <Typography variant={'body1'} sx={localStyles.textStyle}>
                            As a dedicated software developer with 5 years of hands-on experience, I've cultivated a diverse skill set across multiple programming languages and frameworks, including Python, PHP, JavaScript,  Typescript, and SQL, as well as advanced tools like Laravel, React, Next.js, Express, and Django. My expertise extends to both frontend and backend development, where I've leveraged technologies like Firebase, GraphQL, Heroku, and Cypress to deliver robust, scalable, and user-friendly solutions.
                        </Typography>
                        <Typography variant={'body1'} sx={localStyles.textStyle}>
                            What drives me is a genuine passion for continuous learning and growth, both personally and professionally. Whether it's exploring new technologies, refining my current skills, or tackling complex challenges, I'm always seeking opportunities to expand my horizons. My goal is not just to write code but to craft elegant, efficient solutions that make a meaningful impact.
                        </Typography>
                        <Typography variant={'body1'} sx={localStyles.textStyle}>
                            I'm enthusiastic about collaborating with innovative teams and contributing to projects that push the boundaries of what's possible in the tech world. Let's connect and explore how I can bring my technical expertise and passion for development to your next project.
                        </Typography>
                    </Grid>
                    <Grid item sx={localStyles.buttonContainer}>
                        <Button  variant={'outlined'} component={Link} to={'/contact'} onClick={() => props.setValue(4)}>GET IN TOUCH</Button>
                    </Grid>
                    <Typography variant={'h4'} sx={localStyles.textStyleTitle} gutterBottom>The Skills I Have</Typography>
                    <Grid item container sx={localStyles.skillSubBox}>
                        <Grid item sx={localStyles.xpDisplayLeft}  container>
                            <Grid item sx={localStyles.xpDisplayContainer} direction={'column'} container >
                                <Typography variant={'h5'} sx={localStyles.textSkillStyle} gutterBottom>Front-end Development</Typography>
                                <Container sx={localStyles.skillsBox}>
                                <Typography variant={'body2'} sx={localStyles.xpText}>{<FaCheck style={localStyles.xpSmallIcon}/>}  React</Typography>
                                    <Typography variant={'body2'} sx={localStyles.xpText}>{<FaCheck style={localStyles.xpSmallIcon}/>}  Next.js</Typography>
                                <Typography variant={'body2'} sx={localStyles.xpText}>{<FaCheck style={localStyles.xpSmallIcon}/>}  React Native</Typography>
                                <Typography variant={'body2'} sx={localStyles.xpText}>{<FaCheck style={localStyles.xpSmallIcon}/>}  TypeScript</Typography>
                                <Typography variant={'body2'} sx={localStyles.xpText}>{<FaCheck style={localStyles.xpSmallIcon}/>}  Material UI</Typography>
                                    <Typography variant={'body2'} sx={localStyles.xpText}>{<FaCheck style={localStyles.xpSmallIcon}/>}  Tailwind</Typography>
                                <Typography variant={'body2'} sx={localStyles.xpText}>{<FaCheck style={localStyles.xpSmallIcon}/>}  Front-End Testing</Typography>
                                    <Typography variant={'body2'} sx={localStyles.xpText}>{<FaCheck style={localStyles.xpSmallIcon}/>}  Cypress </Typography>

                                </Container>
                            </Grid>
                        </Grid>
                        <Grid item sx={localStyles.xpDisplayRight}   container>
                            <Grid item sx={localStyles.xpDisplayContainer} direction={'column'} container>
                                <Typography variant={'h5'} sx={localStyles.textSkillStyle} gutterBottom>Back-end Development</Typography>
                                <Container sx={localStyles.skillsBox}>
                                <Typography variant={'body2'} sx={localStyles.xpText}>{<FaCheck style={localStyles.xpSmallIcon}/>}  Python - Django</Typography>
                                    <Typography variant={'body2'} sx={localStyles.xpText}>{<FaCheck style={localStyles.xpSmallIcon}/>}  Express Node.js</Typography>
                                    <Typography variant={'body2'} sx={localStyles.xpText}>{<FaCheck style={localStyles.xpSmallIcon}/>}  PHP - Laravel</Typography>
                                <Typography variant={'body2'} sx={localStyles.xpText}>{<FaCheck style={localStyles.xpSmallIcon}/>}  Api Design</Typography>
                                <Typography variant={'body2'} sx={localStyles.xpText}>{<FaCheck style={localStyles.xpSmallIcon}/>}  Database Design</Typography>
                                <Typography variant={'body2'} sx={localStyles.xpText}>{<FaCheck style={localStyles.xpSmallIcon}/>}  Git/GitHub</Typography>
                                    <Typography variant={'body2'} sx={localStyles.xpText}>{<FaCheck style={localStyles.xpSmallIcon}/>}  GraphQL</Typography>
                                    <Typography variant={'body2'} sx={localStyles.xpText}>{<FaCheck style={localStyles.xpSmallIcon}/>}  Testing Libraries</Typography>
                                    <Typography variant={'body2'} sx={localStyles.xpText}>{<FaCheck style={localStyles.xpSmallIcon}/>}  Machine Learning (with Python and TensorFlow)</Typography>
                                </Container>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container sx={localStyles.skillSubBox} >
                        <Grid item sx={localStyles.xpDisplayLeft}  container>
                            <Grid item sx={localStyles.xpDisplayContainer} direction={'column'} container>
                                <Typography variant={'h5'} sx={localStyles.textSkillStyle} gutterBottom>Application Support and Management</Typography>
                                <Container sx={localStyles.skillsBox}>
                                    <Typography variant={'body2'} sx={localStyles.xpText}>{<FaCheck style={localStyles.xpSmallIcon}/>}  CI/CD - GitHub Actions</Typography>
                                    <Typography variant={'body2'} sx={localStyles.xpText}>{<FaCheck style={localStyles.xpSmallIcon}/>}  AWS</Typography>
                                    <Typography variant={'body2'} sx={localStyles.xpText}>{<FaCheck style={localStyles.xpSmallIcon}/>}  Remote Monitoring</Typography>
                                    <Typography variant={'body2'} sx={localStyles.xpText}>{<FaCheck style={localStyles.xpSmallIcon}/>}  Security Best Practices</Typography>
                                    <Typography variant={'body2'} sx={localStyles.xpText}>{<FaCheck style={localStyles.xpSmallIcon}/>}  App-Store Deployment</Typography>
                                    <Typography variant={'body2'} sx={localStyles.xpText}>{<FaCheck style={localStyles.xpSmallIcon}/>}  Docker </Typography>
                                </Container>

                            </Grid>
                        </Grid>
                        <Grid item sx={localStyles.xpDisplayRight}   container>
                            <Grid item sx={localStyles.xpDisplayContainer} direction={'column'} container>
                                <Typography variant={'h5'} sx={localStyles.textSkillStyle} gutterBottom>Consulting</Typography>
                                <Container sx={localStyles.skillsBox}>
                                <Typography variant={'body2'} sx={localStyles.xpText}>{<FaCheck style={localStyles.xpSmallIcon}/>}  Architecture Planning</Typography>
                                <Typography variant={'body2'} sx={localStyles.xpText}>{<FaCheck style={localStyles.xpSmallIcon}/>}  Data Structure Planning</Typography>
                                <Typography variant={'body2'} sx={localStyles.xpText}>{<FaCheck style={localStyles.xpSmallIcon}/>}  Tools and Stack Planning</Typography>
                                <Typography variant={'body2'} sx={localStyles.xpText}>{<FaCheck style={localStyles.xpSmallIcon}/>}  Goal Setting</Typography>
                                <Typography variant={'body2'} sx={localStyles.xpText}>{<FaCheck style={localStyles.xpSmallIcon}/>}  Branding</Typography>
                                </Container>
                            </Grid>
                        </Grid>
                    </Grid>
                    <div style={{marginBottom:'6rem'}}></div>
                    <Typography variant={'h4'} sx={localStyles.textStyleTitle} gutterBottom>Some of my projects</Typography>
                    <Grid item container sx={localStyles.skillSubBox}>
                        <Grid item sx={localStyles.xpDisplayLeft}  container>
                            <Grid item sx={localStyles.xpDisplayContainer} direction={'column'} container >
                            <PortfolioCard
                                images={imagesImi}
                                logo={FX}
                                title={'Flexytrainer'}
                                text={'Online booking system and blog.'}
                                stack={'Django | PostgreSQL | AWS | React | Redux | CI/CD'}
                            />
                            </Grid>
                        </Grid>
                        <Grid item sx={localStyles.xpDisplayRight}   container>
                            <Grid item sx={localStyles.xpDisplayContainer} direction={'column'} container >
                            <PortfolioCard
                                images={imagesNiki}
                                logo={Niki}
                                title={'Niki V Art'}
                                text={'Art Gallery and Portfolio website.'}
                                stack={'Django | PostgreSQL | AWS | Heroku | React | Redux | CI/CD'}
                            />
                            </Grid>
                        </Grid>
                    </Grid>
                    <div style={{marginBottom:'6rem'}}></div>
                    <Endors />
                </Grid>
            </Grid>
        </>
    )
};