import React, {useState, useEffect} from "react";
import {
    AppBar,
    Toolbar,
    Tabs,
    Tab,
    SwipeableDrawer,
    List,
    ListItem,
    ListItemText,
} from "@mui/material";

import {useScrollTrigger} from "@mui/material";
import {Link} from "react-router-dom";
import {useMediaQuery} from "@mui/material";
import {useTheme} from "@mui/material";
import {IconButton} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import {theme} from "../Theme/Theme";
import {styled} from "@mui/styles";
import {useLocation} from "react-router-dom";


function ElevationScroll(props) {
    const { children } = props;

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}


const GepForAppBar = styled('div')(({theme}) => ({
    ...theme.mixins.toolbar,
    //marginBottom:"auto",
    [theme.breakpoints.down('md')]: {
        //marginBottom: '-0.6em'
    },
    [theme.breakpoints.down('xs')]: {
        //marginBottom: '-0.75em'
    }
}))

const sxStyles = {
    appbar:{
        zIndex: theme.zIndex.modal + 1,
        alignItems:'center',
        [theme.breakpoints.down('md')]:{
            alignItems: 'flex-end'
        }
    },
    drawer:{
        backgroundColor: theme.palette.common.inkBlue
    }
};

/*
const routes = [
    {name:'About', link:'/', activeIndex: 0},
    {name:'Simple Websites', link:'/website', activeIndex: 1},
    {name:'Modern Web Apps', link:'/e-com', activeIndex: 2},
    {name:'Resume', link:'/resume', activeIndex: 3},
    {name:'Projects', link:'/projects', activeIndex: 4},
    {name:'Contact', link:'/contact', activeIndex: 5}
];

 */

const routes = [
    {name:'About', link:'/', activeIndex: 0},
    {name:'Resume', link:'/resume', activeIndex: 1},
    {name:'Projects', link:'/projects', activeIndex: 2},
    {name:'Videos', link:'/videos', activeIndex: 3},
    {name:'Contact', link:'/contact', activeIndex: 4}
];



export default function Header (props) {

    // listen to location change in browser so healer is always showing the right state
    let location = useLocation();

    useEffect(() => {
        routes.forEach(route => {
            switch (window.location.pathname) {
                case `${route.link}`:
                    if(props.value !== route.activeIndex) {
                        props.setValue(route.activeIndex)
                        if(route.selectedIndex && route.selectedIndex !== props.selectedIndex){
                            props.setSelectedIndex(route.selectedIndex)
                        }
                    }
                    break;
                default:
                    break;
            }
        })

    },[props.value,props.selectedIndex,props,location])



    const [openDrawer, setOpenDrawer] = useState(false);

    const handleChange = (event, newValue) => {
        props.setValue(newValue);
    };




   // const classes = ''
   // const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));
    const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

    const tabs = (
        <>
            <Tabs value={props.value} onChange={handleChange} >
                {
                    routes.map((route, index) => (
                        <Tab
                            key={index}
                            component={Link}
                            to={route.link}
                            label={route.name}
                            onMouseOver={route.mouseOver}
                        />
                    ))
                }
            </Tabs>
        </>
    );

    const drawer = (
        <>
            <SwipeableDrawer
                disableBackdropTransition={!iOS}
                disableDiscovery={iOS}
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
                onOpen={() => setOpenDrawer(true)}
                classes={{paper:sxStyles.drawer}}
            >

                <GepForAppBar />

                <List disablePadding>
                    {
                        routes.map((route, index) => (
                            <ListItem
                                key={`${route.activeIndex}${index}`}
                                onClick={() => {setOpenDrawer(false); props.setValue(route.activeIndex)}}
                                divider
                                button
                                component={Link}
                                to={route.link}
                                selected={props.value === route.activeIndex}
                            >
                                <ListItemText disableTypography>{route.name}</ListItemText>
                            </ListItem>
                        ))
                    }
                </List>
            </SwipeableDrawer>
            <IconButton onClick={() => setOpenDrawer(!openDrawer)} disableRipple>
                <MenuIcon />
            </IconButton>
        </>
    )


    return(
        <>
            <ElevationScroll>
                <AppBar position={"sticky"} color={"primary"}  sx={sxStyles.appbar}>
                    <Toolbar disableGutters>
                        {
                            matches ? drawer : tabs
                        }
                    </Toolbar>
                </AppBar>

            </ElevationScroll>

            {/*
            <GepForAppBar />
            */}

        </>
    )
}