import React, {useEffect} from "react";
import {Grid, Typography} from "@mui/material";
import {theme} from "../Theme/Theme";
import {baseTheme} from "../Theme/Theme";
import resumeBackground from "../Assest/resume_background2.jpg";



const localStyles = {
    background:{
        backgroundImage: `url(${resumeBackground})`,
        backgroundPosition:'center',
        backgroundSize:'cover',
        backgroundAttachment:'fixed',
        backgroundRepeat:'no-repeat',
        minHeight:'200vh',
        height:'auto',
        width:'100%',
        //opacity:0.7,
        [theme.breakpoints.down("md")]:{
            height:'auto'
        },
        [theme.breakpoints.down("sm")]:{
            //height: '50em'
        }
    },
    mainBody:{

        backgroundColor:baseTheme.white,
        padding:'8rem 10rem',
        opacity:0.85,
        minHeight:'100vh',
        height:'auto',
        width:'100%',
        [theme.breakpoints.down("md")]:{
            padding:'2rem',
            minHeight:'100vh'
        },
        [theme.breakpoints.down("sm")]:{
            minHeight:'100vh',
            height:'auto'
        }
    },
    buttonContainer:{
        paddingTop:'2rem'
    },
    mainHeader:{
        height:'100vh',
    },
    webHeaderText:{
        fontSize:'4rem',
        [theme.breakpoints.down("md")]:{
            fontSize:'3rem'
        }
    },
    webHeaderTextContainer:{
        paddingBottom:'10rem',
        paddingRight:'0.5rem',
        paddingLeft: '0.5rem',
        [theme.breakpoints.down("md")]:{
            paddingBottom: '2em'
        }
    },
    webHeaderTextSub:{
        fontSize: '2rem',
        [theme.breakpoints.down("md")]:{
            fontSize:'1.25rem'
        }
    },
    textStyle: {
        [theme.breakpoints.down("md")]:{
            //textAlign:'center',
            padding: '1rem'
        }
    }
};



export default function Resume (props) {

    useEffect(() => {
        //window.scrollTo({ top: 0, behavior: 'smooth' });
        let top = document.getElementById('top');
        top.scrollIntoView({behavior: 'smooth'});
    },[]);


    return(
        <>
            <Grid container direction={'column'} sx={localStyles.background} justifyContent={'center'}>

                {/*web page header*/}
                <Grid id={'top'} container item direction={'row'} sx={localStyles.mainHeader} >

                    <Grid container item justifyContent={'center'} alignItems={'center'}>
                        <Grid container item sx={localStyles.webHeaderTextContainer} justifyContent={'center'}>
                            <Grid item sx={{backgroundImage:'linear-gradient(to right, RGBA(21,0,80,0.2), RGBA(21,0,80,1))' , padding:'1em', opacity:1, border:`1px solid ${baseTheme.brightPurple}`, borderRadius:'10px'}} >
                            <Typography sx={localStyles.webHeaderText} variant={'h1'} textAlign={'center'} gutterBottom>“Knowledge is power.”</Typography>
                            <Typography sx={localStyles.webHeaderTextSub} variant={'subtitle1'} textAlign={'center'}> – Francis Bacon</Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>

                {/*web page body*/}
                <Grid container item direction={'column'} sx={localStyles.mainBody} justifyContent={'center'} >
                    <Grid item>
                        <Typography sx={localStyles.textStyle} variant={'h2'} gutterBottom>The never ending journey for seeking knowledge</Typography>
                        <Typography sx={localStyles.textStyle} variant={'body1'} gutterBottom>
                            My interest in coding started more than decade ago. My first exposure to programming was
                            through my old gaming habits. It start out small with building macros and simple addons.
                            But it give me a good sens how powerful codes are. Over time I start adventuring deeper
                            into the wilderness of technology's, and here I am a decade later still excited about programming.
                            It would be a really long list to gather every tool and service I consider myself an
                            advanced user of. To keep it short therefore, you can expect me to be able to use and
                            respect modern standards with regard to how to write code effectively, collaborate version
                            control, set up and manage servers, deploy and support software.
                        </Typography>
                        <Typography variant={'h2'} gutterBottom sx={localStyles.textStyle}>My current stack</Typography>
                        <Typography variant={'body1'} gutterBottom sx={localStyles.textStyle}>
                            The technologies I work with are HTML, CSS, JavaScript, Typescript, PHP, Laravel, Cypress, Jest, Node.js, Python, React.js, React Native, Tailwind,
                            Django, Redux, Express, Firebase, MUI, Rest-framework, Celery, Selenium, Git and GitHub, SQL, Heroku, Testing, Photoshop, Adobe XD and AWS. Plus additional sub-services as
                            needed.<br />
                            At the moment on my journey to learn and master Machine Learning and AI development.
                        </Typography>
                        <Typography variant={'h2'} gutterBottom sx={localStyles.textStyle}>Professional Experience</Typography>
                        <Typography variant={'body1'} sx={localStyles.textStyle}>
                            <Typography variant={'h4'} gutterBottom sx={localStyles.textStyle}>2019-present: Range of freelance, contract and full time full stack development projects
                                from planning to completion.</Typography>

                            {/* Rouic */}
                            <Typography variant={'h5'} gutterBottom sx={localStyles.textStyle}>2023 - Senior Full Stack Developer (permanent) Rouic Ltd</Typography>
                            <Typography variant={'body1'} gutterBottom sx={localStyles.textStyle}>
                                Working in a team of developers, to build and maintain multiple web apps
                                <br/>
                                • Designed and developed websites and web applications using HTML,
                                CSS, JavaScript, Python, MySQL and other web technologies.
                                <br/>
                                • Designed and implemented user interfaces using front-end development
                                frameworks such as React, Redux, Next.js.
                                <br />
                                • Developed server-side scripts using Express.js to interact with
                                databases and implement dynamic features.
                                <br />
                                • Collaborated with client and the developer team to ensure the timely delivery of
                                high-quality web application.
                            </Typography>

                            {/* FlexAbility*/}
                            <Typography variant={'h5'} gutterBottom sx={localStyles.textStyle}>2022-2023 - Full Stack Developer (contract) Flexytrainer </Typography>
                            <Typography variant={'body1'} gutterBottom sx={localStyles.textStyle}>
                                Hired to build a Web App. Supporting a small business and help handling online bookings, blog, marketing ...ect
                                <br/>
                                • Designed and developed websites and web applications using HTML,
                                CSS, JavaScript, Python, MySQL and other web technologies.
                                <br/>
                                • Designed and implemented user interfaces using front-end development
                                frameworks such as React, Redux.
                                <br />
                                • Developed server-side scripts using Python and Django to interact with
                                databases and implement dynamic features.
                                <br />
                                • Collaborated with client, to ensure the timely delivery of
                                high-quality web application.
                            </Typography>

                            {/*The Garson*/}
                            <Typography variant={'h5'} gutterBottom sx={localStyles.textStyle}>2021 - 2022 - Project Lead (contract) The Garson</Typography>
                            <Typography variant={'body1'} gutterBottom sx={localStyles.textStyle}>
                                Hired to build a MVP for business idea.
                                <br/>
                                • Designed and developed websites and web applications using HTML,
                                CSS, JavaScript, Python, MySQL and other web technologies.
                                <br/>
                                • Designed and implemented user interfaces using front-end development
                                frameworks such as React, Redux.
                                <br />
                                • Developed server-side scripts using Python and Django to interact with
                                databases and implement dynamic features.
                                <br />
                                • Collaborated with client, to ensure the timely delivery of
                                high-quality web application.
                            </Typography>
                            {/*Sync Bin*/}
                            <Typography variant={'h5'} gutterBottom sx={localStyles.textStyle}>2020 - 2020 - Front End Developer (contract) SyncBin </Typography>
                            <Typography variant={'body1'} gutterBottom sx={localStyles.textStyle}>
                                Hired to implement front-end.
                                <br/>
                                • Implemented design for user interfaces using front-end development
                                frameworks such as React.
                                <br/>
                                • Collaborated with project manager, to ensure the timely delivery of
                                high-quality web application.
                            </Typography>
                            {/*Bot*/}
                            <Typography variant={'h5'} gutterBottom sx={localStyles.textStyle}>2019 - 2020 - Automation Developer (contract) Private Hire </Typography>
                            <Typography variant={'body1'} gutterBottom sx={localStyles.textStyle}>
                                Hired to create a back-end system to collect data from web and automate minor jobs.
                                <br/>
                                •  Designed and built a system for collecting data from the web.
                                <br/>
                                •  Used Python and Selenium to build scraping bots and to automate some web tasks.
                                <br />
                                • Collaborated with project manager, to ensure the timely delivery of
                                high-quality web application.
                            </Typography>
                        </Typography>
                    </Grid>

                </Grid>

            </Grid>
        </>
    )
};
