import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, CardActions } from '@mui/material';
import {GalleryModal} from "./Image-slider/Gallery-modal";
import {theme} from "../Theme/Theme";


const localStyles = {
    textSkillStyle: {
        [theme.breakpoints.down("md")]:{
            //textAlign:'center',
            padding: '1rem',
            fontSize: '1.2rem',
            fontWeight: 600,
        }
    },
    cardText:{
        textAlign:'start',
        [theme.breakpoints.down("lg")]:{
            fontSize: '1rem',
        }
    },
    buttonContainer:{
        padding: '1rem',
    }

}
export default function PortfolioCard({images, logo, title, text, stack}) {
    return (
        <Card sx={{ width: '100%' }}>
            <CardActionArea>
                <CardMedia
                    component="img"
                    height="350"
                    image={logo}
                    alt={`${title} logo`}
                />
                <CardContent>
                    <Typography variant={'h5'} sx={localStyles.textSkillStyle} gutterBottom>{title}</Typography>
                    <Typography variant={'body2'} sx={localStyles.cardText}>{text}</Typography>
                    <Typography variant={'body1'} sx={localStyles.cardText}>{stack}</Typography>
                </CardContent>
            </CardActionArea>
            <div style={{ marginBottom: '3rem'}}/>
            <CardActions sx={localStyles.buttonContainer}>
                <GalleryModal images={images} />
            </CardActions>
        </Card>
    );
}