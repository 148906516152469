import {createTheme} from "@mui/material";
import {createContext} from "react";


//**** working on dark/light swich
export const ThemeContext = createContext();

export const ThemeProvider = ({children}) => {
    return(
        <ThemeContext.Provider value={'fooo'}>{children}</ThemeContext.Provider>
    )
};
//****

const mode = {
    light:'light',
    dark:'dark'
}


export const baseTheme = {
    deepBlack: '#000000',
    deepBlackGlass: 'rgba(0,0,0,0.3)',
    deepPurple: '#3E065F',
    //inkBlue: '#150050',
    inkBlue: '#050510',
    midPurple: '#700B97',
    brightPurple: '#8E05C2',
    brightLightPurple: '#c550ff',
    brightGold: '#FFC60B',
    brightOrange: '#FF8B00',
    arcGrey:'#868686',
    lightGrey:'#dedede',
    white: 'white'
};

// not sure about his 2 theme system...
//let themes = createTheme();
export const theme = createTheme({
    palette:{
        mode:mode.light,
        common:{
            deepBlack: baseTheme.deepBlack,
            deepPurple: baseTheme.deepPurple,
            midPurple: baseTheme.midPurple,
            brightPurple: baseTheme.brightPurple,
            brightGold: baseTheme.brightGold,
            brightOrange: baseTheme.brightOrange,
            arcGrey: baseTheme.arcGrey,
            inkBlue: baseTheme.inkBlue

        },
        primary: {
            main: baseTheme.inkBlue
        },
        secondary: {
            main: baseTheme.brightPurple
        }
    },
    components:{
        MuiAppBar:{
            styleOverrides:{
                root:{
                    alignItems:'center'
                }
            }
        },
        MuiTabs:{
            styleOverrides:{
                indicator:{
                   backgroundColor:baseTheme.brightPurple
                }
            }
        },
        MuiTab:{
            styleOverrides:{
                root:{
                    minWidth:10,
                    marginLeft:'25px',
                    color:baseTheme.white,
                    opacity:0.7,
                    fontFamily:'Raleway',
                    textTransform:'none',
                    fontWeight:400,
                    fontSize:'1rem',
                    "&.Mui-selected":{
                        color: baseTheme.white,
                        opacity:1,
                    }
                }
            }
        },
        MuiListItem:{
          styleOverrides:{
              root:{
                  color:baseTheme.white,
                  opacity:0.7,
                  fontFamily:'Raleway',
                  textTransform:'none',
                  fontWeight:400,
                  fontSize:'1rem',
                  "&.Mui-selected":{
                      color: baseTheme.white,
                      opacity:1,
                  }
              }
          }
        },
        MuiSvgIcon:{
            styleOverrides:{
                root:{
                    color:baseTheme.brightPurple,
                    fontSize: '2.7rem'
                },
                colorSecondary:{
                    fontSize: '2.3rem',
                    '&:hover':{
                        cursor:'pointer'
                    }
                }
            }
        },
        MuiDrawer:{
            styleOverrides:{
                paper:{
                    backgroundColor:baseTheme.inkBlue
                }
            }
        },
        MuiToolbar:{
            styleOverrides:{
                root:{
                    paddingRight:'1rem'
                }
            }
        },
        MuiButton:{
            styleOverrides:{
                root:{
                    color:baseTheme.brightPurple,
                    border:`1px solid ${baseTheme.brightPurple}`,
                    //boxShadow:3,
                    boxShadow:`2px 2px 5px ${baseTheme.brightPurple}`,
                    '&:hover':{
                        color:baseTheme.white,
                        border:`1px solid ${baseTheme.white}`,
                        backgroundColor:baseTheme.brightPurple
                    }
                }
            }
        },
        MuiLink:{
            styleOverrides:{
                root:{
                    color:'blue',
                    transition:'all 800ms ease',
                    '&:hover':{
                        color:'lightblue'
                    }
                }
            }
        },
        MuiContainer:{
            styleOverrides:{
                root:{
                    paddingRight: 0,
                    paddingLeft: 0,
                }
            }
        }

    },
    typography:{
        estimateButton:{
            fontFamily:'Pacifico',
            fontSize:'1rem',
            textTransform:'none',
            color:'white'
        },
        h1:{
            fontFamily:'Raleway',
            fontWeight: 700,
            fontSize: '2.5rem',
            color:`${baseTheme.white}`,
            lineHeight:1.5,
        },
        h2:{
            fontFamily:'Raleway',
            fontWeight: 700,
            fontSize: '2.5rem',
            color:`${baseTheme.brightPurple}`,
            lineHeight:1.5
        },
        h5:{
            fontFamily:'Raleway',
            fontWeight: 500,
            fontSize: '1.3rem',
            color:`${baseTheme.brightPurple}`,
            lineHeight:1.5
        },
        h4:{
            fontFamily:'Raleway',
            fontWeight: 700,
            fontSize: '1.5rem',
            color:`${baseTheme.brightPurple}`,
            lineHeight:1.5
        },
        h3:{
            fontFamily:'Raleway',
            fontSize: '2.5rem',
            color:baseTheme.brightPurple
        },
        subtitle1:{
            fontFamily:'Raleway',
            fontSize:'1rem',
            fontWeight:400,
            color:baseTheme.white
        },
        subtitle2:{
            color:'white',
            fontSize:'1.25rem',
            fontWeight:300
        },
        body1:{
            fontSize:'1.25rem',
            fontWeight:300,
            color:`${baseTheme.arcGrey}`,
            fontFamily:'Roboto'
        },
        body2:{
            fontSize:'1.25rem',
            fontWeight:500,
            color:`${baseTheme.deepBlack}`,
            fontFamily:'Roboto'
        },
        caption:{
            color:`${baseTheme.arcGrey}`,
            fontSize:'1rem',
            fontWeight:400,
            fontFamily:'Roboto'
        },
        learnButton:{
            borderColor:`${baseTheme.brightPurple}`,
            color:`${baseTheme.brightPurple}`,
            borderWidth: 2,
            textTransform:'none',
            borderRadius:50,
            fontFamily:'Roboto',
            fontWeight:'bold',
        }
    }
});

//export default theme;